<template>
<div>
    <div class="d-flex justify-start align-center" style="height:60px;color: #d31145;background-color:#fff;padding: 0 20px 0 20px;">
      <v-icon color="#d31145" @click="$router.push('/inventory')">mdi-arrow-left</v-icon>
      <span style="padding-left:15px;font-weight:700">Stok</span>
    </div>
    
    <v-divider></v-divider>

    <div>
      <div v-if="stock_list.length > 0 || searching != ''" class="d-flex justify-space-around align-center pa-4" style="background-color:#ffffff;height:60px">
        <v-text-field
          dense
          v-model="searching"
          :hide-details="true"
          color="#ACACAC"
          filled
          rounded
          placeholder="Search..."
          elevation="0">
          <template v-slot:prepend-inner>
            <v-icon 
              color="#ACACAC">
              mdi-magnify
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon 
              color="#FF2353"  
              v-show="searching != ''"
              @click="
                searching = '';
                initialize()">
              mdi-close-circle
            </v-icon>
          </template>
        </v-text-field>

        <v-btn icon class="ml-4" color="#d31145">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>

      </div>

      <div v-if="stock_list.length > 0" class="px-5" style="height: 70px; background-color:#fff;">
        <v-alert
          dense
          text
          icon="mdi-alert-outline"
          color="#FF9800">
          Baris yang berwarna merah merupakan stok yang kurang dari, atau sama dengan stok minimum
        </v-alert>
      </div>

      <v-card flat v-if="stock_list.length < 1 && searching == '' && !process.run">
        <v-card-text style="padding: 20px 20px">
          <div style="font-weight:700;font-size:16pxline-height: 19px;padding-bottom: 5px">
            Anda belum menambahkan Produk
          </div>

          <div style="font-weight: normal;font-size: 14px;color:#ACACAC;line-height: 1.5;padding-bottom: 5px">
            Belum ada produk atau layanan pada toko ini. 
            <br>
            Anda bisa menambahkan dengan menekan tombol di bawah.
          </div>

          <div style="padding-top:20px">
            <v-btn to="/master/product/form" rounded depressed color="#d31145" elevation="0" class="white--text text-capitalize">
              <v-icon left>mdi-plus</v-icon>
              Produk
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card flat v-else-if="stock_list.length < 1 && searching != ''">
        <v-card-text
          class="d-flex align-center"
          style="height: calc(100vh - 200px); background: #f9f9f9; ">
            <v-img
              class="mx-auto"
              max-width="200"
              src="https://sodapos.com/media/2021/12/image/no-result-1638951068.png">
            </v-img>
        </v-card-text>
      </v-card>

      <v-card flat v-else>
        <v-card-text 
          class="px-0 pb-0 py-0" 
          style="
            overflow: auto; 
            height: calc(100vh - 210px);
            background: #f9f9f9;
            padding-bottom: 65px !important;">

            <div v-if="stock_list.length < 1 && searching == '' && process.run">
              <v-skeleton-loader
                v-for="item in 15" :key="item"
                type="list-item-avatar-two-line">
              </v-skeleton-loader>
            </div>

          <v-list dense subheader three-line class="py-0">
            <template v-for="(item, i) in stock_list">
              <v-list-item :key="item.id" class="py-0" @click="getDetail(item)">
                <v-list-item-avatar tile size="50" style="border-radius:5px !important">
                  <v-img
                    width="50"
                    height="50"
                    :src="item.image_url">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          color="#d31145">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 16px;" class="font-weight-bold text-title text-capitalize">
                    {{ item.product_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    <div class="d-flex align-center">
                      <div class="text-start pr-5">
                        <div class="text-capitalize txet-title">
                          {{ item.product_category_name }}
                        </div>
                        <div class="text-red">
                          SKU : {{ item.sku }}
                        </div>
                      </div>
                      <div style="border: 1px solid #FA91AD57;height:35px;"></div>
                      <div class="text-start pl-5 text-title">
                        <div>
                          Stok Sekarang : {{ item.stock_balance }}
                        </div>
                        <div>
                          Stok Minimum : {{ item.stock_minimum }}
                        </div>
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="i" class="mx-auto"></v-divider>
            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-btn v-if="stock_list.length > 0" class="float white--text text-capitalize" rounded color="#207245">
      <v-icon color="#fff" class="my-float" left>mdi-microsoft-excel</v-icon>
      Export Excel
    </v-btn>

    <v-dialog v-model="dialog.detail" persistent width="400">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Detail Stok
          </div>
          <v-icon @click="dialog.detail = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-card-text class="text-center py-4">

          <div class="d-flex justify-start align-center">
            <v-img
              contain
              width="70"
              height="70"
              class="rounded-xl mx-auto"
              style="flex: 1"
              :src="detail.image_url">
            </v-img>
            <div class="text-start pl-2" style="width:250px" >
              <div class="mb-2 subtitle-1 font-weight-bold">
                {{ detail.product_name }}
              </div>
              <div class="d-flex justify-start align-center text-title">
                <div style="width: 90px">Kategori</div>
                <div>:</div>
                <div style="flex: 1; padding-left: 10px">{{ detail.product_category_name }}</div>
              </div>
              <div class="d-flex justify-start align-center text-title">
                <div style="width: 90px">Kode</div>
                <div>:</div>
                <div class="text-red" style="flex: 1;padding-left: 10px;font-weight: 600">{{ detail.sku }}</div>
              </div>
            </div>
          </div>

          <div style="border: 1px dashed #FA91AD;width: 100%;" class="mt-7"></div>

          <v-row class="py-5">
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Awal
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_init }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Minimum
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_minimum }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Sekarang
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_balance }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Pembelian
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_po }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Penjualan
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_penjualan }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Penyesuaian
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_adjustment }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Konversi
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_converter }}
              </div>
            </v-col>
            <v-col cols="4">
              <div style="color: #ACACAC;font-size: 12px;padding-bottom: 22px;">
                Stok Transfer
              </div>
              <div style="font-size: 30px" class="text-red font-weight-bold">
                {{ detail.stock_transfer }}
              </div>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
export default {
  data: () => ({
    stock_list: [],
    pagination: {},
    searching: "",
    detail: {},
    process: {
      run: false
    },
    dialog: {
      detail: false,
    }
  }),
  components: {

  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;

      this.initialize();
    }, 500),
  },
  computed: {

  },
  created() {

  },
  mounted(){
    this.initialize()
  },
  methods: {
    goBack(){
      this.$router.push(`/inventory`)
    },
    async initialize(){
      this.process.run = true
      let params = {
        search: this.searching,
        sort: "",
        dir: "",
        limit: 1000,
        page: 1
      }
      let res = await this.$get(`pos/stock`, params)

      if (res.status == 200) {
        this.process.run = false
        this.stock_list = res.results.data
        this.pagination = res.results.pagination
      }

      this.process.run = false
    },
    getDetail(item){
      console.log(item);
      this.detail = item
      this.dialog.detail = true
    },
  },
}
</script>